.subHeader_bg {
  background-color: #0c7bc0;
}

::selection {
  color: rgb(255, 255, 255);
  background: #242323;
}

.common_color {
  color: #0c7bc0;
  font-weight: 600;
  padding: 10px;
}

.icon_sub_header {
  height: 20px;
  width: 20px;
  margin-right: 20px;
  margin: 6px;
}

.font_size_14px {
  font-size: 14px;
}

.section_Four_bg {
  background-image: url('../public/images/sectionFour.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* .content {
  width: 500px;
  padding: 2em 3em 2em 3em;
  margin: 230px auto;
} */
@keyframes zoomIns {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.content {
  width: 500px;
  padding: 2em 3em 2em 3em;
  margin: 230px auto;
  transform: scale(1); /* Initial scale */
  transition: all 1s;
  animation: zoomIns 3s; /* Apply the zoom-in animation */
}

.section_two_sub {
  color: #0c7bc0;
  font-family: 'Lato', Sans-serif;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  line-height: 1.3em;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.counter_bg {
  background-color: rgb(255, 255, 255);
  border-bottom: 4px solid #0c7bc0;
  box-shadow: 1px 1px 20px 11px lightblue;
  transform: translateY(0px);
  transition: all 1s;
  animation: slideIn 3s;
}

.navbar_style {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
}

.tAreas_Button {
  height: 50px;
  width: 100%;
  padding-top: 19px;
  padding-bottom: 39px;
  border-radius: 30px;
  font-weight: bold;
  color: black;
  background-color: white;
  border: 1px solid rgb(17, 0, 255);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
}

.tAreas_Button:hover {
  background-color: transparent;
  background-image: linear-gradient(180deg, #0c7bc0 0%, #6ebced 100%);
}

@media screen and (max-width: 480px) {
  .subHeader_bg {
    display: none;
  }
}

.submit_btn {
  margin-top: 5px;
  background-color: #0c7bc0;
  color: white;
  border: none;
  width: 100px;
  padding: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500;
}

.contact_us_bg_img {
  background-image: url('../public/images/contact_us.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.8;
  filter: brightness(60%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
  background-position: center center;
  height: 520px;
}

.numberOne {
  background-color: #0c7bc0;
  color: white;
  font-family: 'Poppins', Sans-serif;
  text-align: center;
  padding: 20px;
  font-size: 40px;
}

.headerMenu {
  color: #0c7bc0 !important;
  margin-left: 22px;
  font-size: 14px;
  font-weight: 600;
}
.img_vision_width {
  max-width: 54%;
}

@media screen and (max-width: 480px) {
  .culture_icons_one {
    text-align: center;
  }
  .content {
    margin: 90px auto;
    width: 90%;
  }
  .img_vision_width {
    max-width: 30%;
  }
  .reachUs_style {
    text-align: center;
  }
}

.back-to-top {
  position: fixed;
  color: white;
  background-color: rgba(43, 38, 38, 0.575);
  right: 20px;
  width: 43px;
  height: 43px;
  bottom: 30px;
  border-radius: 5%;
  z-index: 99;
}

.btn:hover {
  background-color: rgb(43, 38, 38);
  color: white;
}

.whatsapp_icon:hover {
  background-color: #156432;
}

.whatsapp_icon {
  color: white;
  background-color: #25d366;
  padding: 9px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  left: 20px;
  width: 43px;
  height: 43px;
  bottom: 30px;
  z-index: 99;
}

.reachUs_style {
  color: #090909;
  font-size: 34px;
  font-weight: 700;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.animiOne {
  height: 100%;
  width: 100px;
  background-color: #0f7dc1;
  animation: animate;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-direction: normal;
}
@keyframes animate {
  0% {
    height: 0;
  }
  100% {
    height: 20%;
  }
}

.btn_find_trail {
  background-color: rgb(12, 123, 192);
  font-size: 16px;
  color: rgb(255, 255, 255);
  line-height: 1.3333;
  padding: 16px 28px;
  border: none;
}

.dropdown-menu {
  display: none;
  position: absolute;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.carousel-control-prev,
.carousel-control-next {
  background: rgb(12, 123, 192);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-top: 230px;
}

.toggle_icon2 {
  height: 30px;
  width: 30px;
  border: none;
}

.toggle_icon3 {
  height: 25px;
  width: 25px;
  margin: 3px;
  border: none;
}

@media screen and (max-width: 575px) {
  .content_center_style {
    text-align: center;
  }
  .address_content_differ {
    margin: 9px;
  }
  .tAreas_Button {
    border: none;
  }
  .model_content_width {
    width: 80%;
  }
}

@media screen and (min-width: 575px) {
  .content_height {
    margin-top: 33px;
  }
  .content_icon {
    margin-top: 20px;
  }
  .navbar_style {
    padding: 22px 0px 22px 100px;
  }
  .model_content_width {
    width: 50%;
  }
}

.dropdown-menu {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 1s ease-in-out;
  transform-origin: top;
}

.headerMenu:hover .dropdown-menu {
  display: block;
  opacity: 1;
  margin-top: 0;
  transition: opacity 0.5s ease-in-out, transform 1s ease-in-out;
  transform: translateY(10px);
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.our_culture {
  background: rgb(255, 255, 255);
  transform: scale(1); /* Initial scale */
  transition: all 1s;
  animation: zoomIn 3s; /* Apply the zoom-in animation */
}

.loading {
  display: inline-block;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.slider_font_size {
  font-size: 49px;
  font-weight: bold;
  padding: 20px;
}
.animiTwo {
  height: 300px;
  width: 400px;
  margin-top: 280px;
  background-color: #0c7bc0;
  animation: animate-wide-ribbon;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-direction: normal;
  position: absolute;
  bottom: -100%;
  right: 0;
  z-index: 1;
  margin-right: -125px;
}
@keyframes animate-wide-ribbon {
  0% {
    top: 100%;
  }
  100% {
    top: -30%;
  }
}

.slider_img_fixed {
  height: 357px !important;
}
.padding_slider_for_mobile {
  padding: 50px;
}

@media screen and (max-width: 768px) {
  .slider_font_size {
    font-size: 27px;
    font-weight: bold;
    padding: 20px;
  }

  .slider_img_fixed {
    /* height: 200px !important; */
    height: 230px !important;
  }

  .animiTwo {
    height: 200px;
    width: 100px !important;
    background-color: #0c7bc0;
    animation: animate-wide-ribbon;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-direction: normal;
    position: absolute;
    bottom: -100%;
    right: 0;
    z-index: -1;
    margin-right: 0px !important;
  }
  @keyframes animate-wide-ribbon {
    0% {
      top: 100%;
    }
    100% {
      top: 0%;
    }
  }

  .padding_slider_for_mobile {
    padding: 10px;
    padding-bottom: 29px;
    /* height: 244px !important; */
  }
  .calc_margin_bottom {
    margin-bottom: 37px;
  }
}

.slide-in-top {
  animation-name: slideInTop;
  animation-duration: 1.2s;
}

@keyframes slideInTop {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.error {
  color: red;
}

.our_culture2 {
  padding: 2px;
}
.our_culture1 {
  padding: 50px 120px;
}
.text-black {
  font-size: 13px;
  line-height: 1.5rem;
}

.section_two_cutting {
  padding: 5em 3em 3em 3em;
}

@media screen and (max-width: 400px) {
  .our_culture2 {
    padding: 2px;
  }
  .our_culture1 {
    padding: 30px;
  }
  .text-black {
    text-align: center;
    font-size: 14px;
    font-family: 'Open Sans' sans-serif;
  }
  .avancing {
    font-size: 20px;
  }
  .section_two_cutting {
    padding: 2em 1em 1em 1em;
  }
}

@media (min-width: 540px) {
  #carouselExampleIndicators {
    padding: 0px 79px;
  }
  .our_culture1 {
    padding: 50px 32px;
  }
}

@media (max-width: 540px) {
  .our_culture1 {
    padding: 50px 32px;
  }
  .my_style_for_navbar {
    position: absolute;
    background: white;
    z-index: +9;
    left: 0;
    top: 0;
    height: 585px;
    width: 90%;
  }
}

@media screen and (min-width: 500px) {
  .mobile_second_logo {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .mobile_second_logo {
    display: block;
  }
}

.log_and_btn_space {
  justify-content: space-between;
}

.btn_css_for_toggle_bar {
  outline: none;
}

.btn_css_for_toggle_bar:focus {
  box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.5);
  border: none;
}
